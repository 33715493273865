import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/4th-annual-conference/5.jpeg';
import blogd2 from '../../assets/images/2024/4th-annual-conference/6.jpeg';
// import blogd3 from '../../assets/images/2024/senate-committee-commends-mgt/3.jpeg';
// import blogd4 from '../../assets/images/2024/senate-committee-commends-mgt/4.jpeg';
// import blogd5 from '../../assets/images/2024/senate-committee-commends-mgt/5.jpeg';
// import blogd6 from '../../assets/images/2024/senate-committee-commends-mgt/6.jpeg';
// import blogd7 from '../../assets/images/2024/senate-committee-commends-mgt/7.jpeg';
// import blogd8 from '../../assets/images/2024/senate-committee-commends-mgt/8.jpeg';
// import blogd9 from '../../assets/images/2024/senate-committee-commends-mgt/9.jpeg';
// import blogd10 from '../../assets/images/2024/senate-committee-commends-mgt/10.jpeg';
// import blogd11 from '../../assets/images/2024/senate-committee-commends-mgt/11.jpeg';
// import blogd12 from '../../assets/images/2024/senate-committee-commends-mgt/12.jpeg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Happy 64th Independence Day, Nigeria!
                </h2>
                <div className='blog-one__image'>
                  <img src={blogd2} alt='' />
                </div>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  From all of us at{' '}
                  <strong>Lagos University Teaching Hospital (LUTH)</strong>, we
                  celebrate this special day with pride and joy. As a nation,
                  we've come a long way, and we continue to move forward with
                  resilience, strength, and unity. At LUTH, we remain committed
                  to contributing to the nation's health and well-being,
                  ensuring every Nigerian receives the best care possible.
                  <br />
                  As we mark this occasion, let’s renew our hopes for a
                  healthier, stronger, and more prosperous Nigeria. Happy
                  Independence Day, Nigeria! <srtong>#NigeriaAt64 #LUTH</srtong>
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd1} height={370} />
                  </div>
                </div>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Community Health Department</a>
                </p>
              </div>
              <div className='social-block'>
                <a href='#none'>
                  <i className='fab fa-twitter'></i>
                </a>
                <a href='#none'>
                  <i className='fab fa-facebook-f'></i>
                </a>
                <a href='#none'>
                  <i className='fab fa-instagram'></i>
                </a>
                <a href='#none'>
                  <i className='fab fa-dribbble'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='LAGOS UNIVERSITY TEACHING HOSPITAL | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
